import { Row, Col } from 'reactstrap';

interface IFoodButtonProps {
    image?: boolean,
    image_file?: string,
    status?: string,
    col?: string,
    name?:string,
    action?: Function,
}

function FoodButton(props:IFoodButtonProps) {
    const appUrl = process.env.REACT_APP_URL;

    const imagePart = () => {
        if(props.image) return (
            <Col xs={props.col === "12" ? "3" : "5"} className="justify-content-center align-self-center text-center">
                <div className="img-box">
                    <img alt="" className="meal-img" src={`${appUrl}${props.image_file}`} />
                </div>
            </Col>
        )
    }

    const button = () => {
        if(props.status === "success") return (
            <Col xs={props.col} className="my-15">
                <label>
                <input type="radio" name="test" value="small"  onClick={() => props.action ? props.action() : null} />
                    <div id="lab" className="meal">
                    <Row className="g-0 pb-2 pt-2 ps-2">
                            {imagePart()}
                            <Col xs={props.image ? props.col === "12" ? "9" : "7" : "12"} className="justify-content-center align-self-center text-center px-1">
                                <div className="meal-name pe-3">{props.name}</div>
                            </Col>
                    </Row>
                    </div>
                </label>
            </Col>
        )
    }

    return (
       <>
        {button()}
       </>
    );
  }


export default FoodButton;
